import React, {useEffect, useMemo, useState} from 'react';
import {ScrollView, Text, TouchableOpacity, View} from 'react-native';
import {useStyle} from 'src/providers/style';
import {Colors, Typography} from 'src/styles';
import {useTranslations} from 'src/providers/translation';
import {FormProvider, useForm, useWatch} from 'react-hook-form';
import {compose} from 'recompose';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import withState from 'src/redux/wrapper';
import withObservables from '@nozbe/with-observables';
import {mergeMap, of} from 'rxjs';
import {startOfDay, endOfDay} from 'date-fns';
import {
  Authorization,
  BillingEntity,
  Caregiver,
  ClaimEvent,
  Credential,
  Diagnosis,
  Instance,
  InstanceDiagnosis,
  Insurance,
  Payer,
  PayerCredential,
  ServiceLine,
  Session,
  User,
} from 'src/models';
import {Q} from '@nozbe/watermelondb';
import InputGroup from 'src/design-system/input-group';
import {
  TIN,
  NPI,
  FirstName,
  MiddleName,
  LastName,
  BirthDate,
  Gender,
  SSN,
} from 'src/hook-form-inputs/';
import AddressAutocompleteInput from 'src/hook-form-wrapper/address-input';
import HookFormInput from 'src/hook-form-wrapper/form-input';
import {Separator} from 'src/common-components/atoms';
import InsuranceForm, {
  defaultPayer,
  defaultSubscriber,
} from 'src/modules/billing/components/insurance-form';
import {PayerItemDisplay} from 'src/modules/billing/components/payer-item';
import DiagnosesForm, {
  defaultDiagnosis,
} from 'src/modules/patients/components/diagnoses-form';
import {FormSectionHeader} from 'src/design-system';
import SessionLineForm from 'src/modules/billing/components/session-line-form';
import {RHButton} from 'src/common-components/custom-ui-helpers';
import {useNavigation} from '@react-navigation/native';
import _ from 'lodash';
import TaxonomySelector from 'src/hook-form-inputs/taxonomy-selector';
import {cptCodes} from 'src/hook-form-inputs/cpt';
import HookFormSelectInput from 'src/hook-form-wrapper/select-input';
import CredentialSelector from 'src/hook-form-inputs/credential-selector';
import CredentialForm from 'src/modules/patients/components/credential-form';
import {useDatabase} from '@nozbe/watermelondb/hooks';
import {useSelector} from 'react-redux';
import moment from 'moment/moment';
import Collapsible from 'react-native-collapsible';
import {IconButton} from 'react-native-paper';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import * as Yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import UserCredential from 'src/hook-form-inputs/user-credential';
import addressFormat from 'src/common-utils/address-format';
import HookFormSwitchInput from 'src/hook-form-wrapper/switch-input';

const ClaimForm = ({
  claim,
  patient,
  insurances,
  diagnoses,
  sessions,
  services,
  organization,
  caregivers,
  rendering,
  referring,
  initialPrimaryPayer,
  billingEntities,
  billingProvider,
  billingOrganization,
}: any) => {
  const database = useDatabase();
  const styles = useStyle();
  const translations = useTranslations();
  const navigation = useNavigation();
  const {selectedGroup, userId} = useSelector(state => state.authentication);
  const [referringPhysician, setReferringPhysician] = useState<boolean | any>(
    referring,
  );
  const [renderingPhysician, setRenderingPhysician] = useState<boolean | any>(
    rendering,
  );
  const [billingPhysician, setBillingPhysician] = useState<boolean | any>(
    billingProvider,
  );
  const [billingEntity, setBillingEntity] = useState<boolean | any>(
    billingOrganization,
  );

  const [primaryPayer, setPrimaryPayer] = useState<any>(initialPrimaryPayer);

  const [priorAuth, setPriorAuth] = useState<any>(null);

  const caregiverAddresses = caregivers
    .map((caregiver: Caregiver) => {
      let result = [];
      if (caregiver.address?.placeId) {
        result.push(caregiver.address);
      }
      if (caregiver.workAddress?.placeId) {
        result.push(caregiver.workAddress);
      }
      return result;
    })
    .flat();

  let patientAndCaregiverAddresses = [];
  if (patient?.address) {
    if (Object.keys(patient?.address).length && patient?.address?.placeId) {
      caregiverAddresses.unshift(patient.address);
    }
  }

  patientAndCaregiverAddresses = _.uniqBy([...caregiverAddresses], 'placeId');

  const groupedServices = _.groupBy(services, 'sessionId');

  const primaryTest = (testName: string, message: string) =>
    Yup.string().test(testName, message, function (value) {
      const {options} = this as Yup.TestContext;
      if (options.index === 0) {
        return value;
      }
      return true;
    });

  const validationSchema = Yup.object({
    patient: Yup.object({
      firstName: FirstName.validation(),
      lastName: LastName.validation(),
      // dob
      // gender
      patientSavedAddress: Yup.string().required('Address is required'),
      address: Yup.object().when('patientSavedAddress', {
        is: (val: string) => val === 'other',
        then: () =>
          Yup.object({
            placeId: Yup.string().required('Address is required'),
          }).test('isValidAddress', 'Address is required', function (value) {
            return Object.keys(value).length;
          }),
      }),
    }),
    insurances: Yup.array()
      .of(
        Yup.object({
          payerId: primaryTest('primaryPayer', 'Payer is required'),
          // planId: primaryTest('primaryPlan', 'Plan is required'),
          memberId: primaryTest('primaryMemberId', 'Member ID is required'),
          // groupNumber: primaryTest(
          //   'primaryGroupNumber',
          //   'Group Number is required',
          // ),
          subscriber: Yup.object({
            firstName: primaryTest(
              'primarySubscriberFirstName',
              'First Name is required',
            ),
            lastName: primaryTest(
              'primarySubscriberLastName',
              'Last Name is required',
            ),
            relationship: primaryTest(
              'primarySubscriberRelationship',
              'Relationship is required',
            ),
            // prior auth
            address: Yup.object().test(
              'isValidAddress',
              'Address is required',
              function (value) {
                const {options} = this as Yup.TestContext;
                if (options.index === 0) {
                  return value;
                }
                return true;
              },
            ),
          }),
        }),
      )
      .min(1, 'At least one Insurance is required')
      .required(),
    diagnoses: Yup.array()
      .of(
        Yup.object({
          instanceDiagnosisId: Yup.string().required('Diagnosis is required'),
        }),
      )
      .min(1, 'At least one diagnosis is required')
      .required(),
    sessions: Yup.array().of(
      Yup.object({
        startDate: Yup.date()
          .test(
            'isValidStartDate',
            'Start Date must fall within Prior Authorization Date Range',
            function (value) {
              if (priorAuth) {
                return (
                  moment(value).isBetween(
                    startOfDay(priorAuth.startDate),
                    endOfDay(priorAuth.endDate),
                  ) || moment(value).isSame(priorAuth.startDate)
                );
              } else {
                return true;
              }
            },
          )
          .required('Start Date is required'),
        endDate: Yup.date()
          .test(
            'isValidEndDate',
            'End Date must fall within Prior Authorization Date Range',
            function (value) {
              if (priorAuth) {
                return (
                  moment(value).isBetween(
                    startOfDay(priorAuth.startDate),
                    endOfDay(priorAuth.endDate),
                  ) || moment(value).isSame(priorAuth.endDate)
                );
              } else {
                return true;
              }
            },
          )
          .required('End Date is required'),
        location: Yup.string().required('POS is required'),
        address: Yup.string().required('Address is Required'),
        // customAddress: Yup.object({
        //   placeId: Yup.string().required('Address is required'),
        // }).test('isValidAddress', 'Address is required', function (value) {
        //   return Object.keys(value).length;
        // }),
        renderingProvider: Yup.object({
          firstName: FirstName.validation(),
          lastName: LastName.validation(),
          npi: NPI.validation(true),
        }),
        // service lines
        // units +
        // amount
      }),
    ),
    referring: Yup.object({
      npi: NPI.validation(),
    }),
  });

  const initialValues = {
    organization: {
      _id: organization.id,
      dba: organization?.dba,
      address: organization?.singleAddress
        ? organization.address
        : organization?.billingAddress,
      tin: organization?.tin || '',
      npi: organization?.npi || '',
      taxonomy: primaryPayer?.taxonomy || '',
    },
    patient: {
      _id: patient.id,
      firstName: patient.firstName,
      middleName: patient.middleName,
      lastName: patient.lastName,
      birthDate: patient.birthDate,
      gender: patient.gender,
      ssn: patient.ssn,
      address: patient.address,
      // billById: patient.billById ? patient.billById : 'facility',
      billByPhysician: patient.billByPhysician,
      patientSavedAddress: patientAndCaregiverAddresses.length
        ? patientAndCaregiverAddresses[0].placeId
        : 'other',
    },
    billById: billingEntity.id,
    billPhysicianId: billingPhysician.id,
    billingProvider: {
      tin: billingPhysician.tin,
      npi: billingPhysician.npi,
      ssn: billingPhysician.ssn,
      taxonomy: billingPhysician.taxonomy,
      firstName: billingPhysician.firstName,
      middleName: billingPhysician.middleName,
      lastName: billingPhysician.lastName,
      payers: [],
    },
    billingOrganization: {
      tin: billingEntity.tin,
      npi: billingEntity.npi,
      dba: billingEntity?.dba,
      address: billingEntity.address,
      taxonomy: billingEntity?.taxonomy || '',
    },
    renderingId: renderingPhysician.id,
    rendering: {
      tin: renderingPhysician.tin,
      npi: renderingPhysician.npi,
      ssn: renderingPhysician.ssn,
      taxonomy: renderingPhysician.taxonomy,
      firstName: renderingPhysician.firstName,
      middleName: renderingPhysician.middleName,
      lastName: renderingPhysician.lastName,
      payers: [],
    },
    referringId: referringPhysician.id,
    referring: {
      tin: referringPhysician.tin,
      npi: referringPhysician.npi,
      ssn: referringPhysician.ssn,
      taxonomy: referringPhysician.taxonomy,
      firstName: referringPhysician.firstName,
      middleName: referringPhysician.middleName,
      lastName: referringPhysician.lastName,
    },
    reorderPayers: false,
    insurances: insurances.length
      ? insurances.map((insurance: any) => ({
          _id: insurance.id,
          groupNumber: insurance.groupNumber,
          memberId: insurance.memberId,
          startDate: insurance.startDate > 0 ? insurance.startDate : undefined,
          endDate: insurance.endDate > 0 ? insurance.endDate : undefined,
          planId: insurance.planId,
          payerId: insurance.payerId,
          patientId: patient.id,
          subscriber: insurance.subscriber || defaultSubscriber,
          authorizationId: insurance.authorizationId,
        }))
      : [defaultPayer],
    reorderDiagnoses: false,
    diagnoses: diagnoses?.length
      ? diagnoses.map((diagnosis: any) => ({
          _id: diagnosis?.id,
          diagnosis: diagnosis.diagnosis || '',
          instanceDiagnosisId: diagnosis.instanceDiagnosisId,
          rank: diagnosis.rank,
          deletedAt: diagnosis?.deletedAt ?? null,
        }))
      : [defaultDiagnosis],
    sessions: sessions.map((session: any) => ({
      _id: session.id,
      renderingId: session.renderingId || session.userId,
      supervisingId: null,
      startDate: session.editedStartTimestamp,
      endDate: session.editedEndTimestamp,
      location: session.location,
      address: session.address,
      customAddress: session.customAddress,
      patient: patient.id,
      services:
        groupedServices[session.id]?.map(service => {
          return {
            _id: service.id,
            cpt: service.cpt,
            description: cptCodes.find(cpt => cpt.value === service.cpt)?.label,
            modifier1: service.modifier1,
            modifier2: service.modifier2,
            modifier3: service.modifier3,
            modifier4: service.modifier4,
            diagnosisPointer: service.diagnosisPointer,
            units: service.units,
            amount: service.amount,
          };
        }) || [],
    })),
    box_8: claim.box8,
    box_9b: claim.box9b,
    box_9c: claim.box9c,
    box_10d: claim.box10d,
    box_19: claim.box19,
    box_30: claim.box30,
  };

  const methods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });

  const watchedPatientAddress = useWatch({
    control: methods.control,
    name: 'patient.patientSavedAddress',
  });

  const watchedBillByPhysician = useWatch({
    control: methods.control,
    name: 'patient.billByPhysician',
  });

  const watchedBillById = useWatch({
    control: methods.control,
    name: 'billById',
  });

  const watchedBillPhysicianId = useWatch({
    control: methods.control,
    name: 'billPhysicianId',
  });

  const watchedInsurances = useWatch({
    control: methods.control,
    name: 'insurances',
  });

  const watchedSessions = useWatch({
    control: methods.control,
    name: 'sessions',
  });

  const watchedPriorAuthId = useWatch({
    control: methods.control,
    name: 'insurances.0.authorizationId',
  });

  const fetchPriorAuth = async () => {
    const fetchedPriorAuth = watchedPriorAuthId
      ? await database.get(Authorization.table).find(watchedPriorAuthId)
      : {};

    if (fetchedPriorAuth) {
      setPriorAuth(fetchedPriorAuth);
    }
  };

  useEffect(() => {
    if (watchedPriorAuthId) {
      fetchPriorAuth();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchedPriorAuthId]);

  const fetchPrimaryPayer = async () => {
    const newPrimaryPayer = watchedInsurances[0]?.payerId
      ? await database.get(Payer.table).find(watchedInsurances[0]?.payerId)
      : {};
    if (newPrimaryPayer) {
      setPrimaryPayer(newPrimaryPayer);
      methods.setValue('organization.taxonomy', newPrimaryPayer.taxonomy || '');
    }
  };

  useEffect(() => {
    fetchPrimaryPayer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchedInsurances]);

  const watchedReferringId = useWatch({
    control: methods.control,
    name: 'referringId',
  });

  const watchedRenderingId = useWatch({
    control: methods.control,
    name: 'renderingId',
  });
  const [missingOrganizationValues, setMissingOrganizationValues] =
    useState<boolean>(false);
  const [missingPatientValues, setMissingPatientValues] =
    useState<boolean>(false);
  const [missingInsuranceValues, setMissingInsuranceValues] =
    useState<boolean>(false);
  const [missingDiagnosesValues, setMissingDiagnosesValues] =
    useState<boolean>(false);

  const [collapseBillingEntity, setCollapseBillingEntity] = useState<boolean>(
    !missingOrganizationValues,
  );
  const [collapsePatientEntity, setCollapsePatientEntity] = useState<boolean>(
    !missingPatientValues,
  );
  const [collapseInsurance, setCollapseInsurance] = useState<boolean>(
    !missingInsuranceValues,
  );
  const [collapseDiagnoses, setCollapseDiagnoses] = useState<boolean>(
    !missingDiagnosesValues,
  );

  const validateServiceValues = () => {
    let isMissingValues = false;

    for (const session of watchedSessions) {
      if (
        !session.startDate ||
        !session.endDate ||
        !session.location ||
        !session.renderingId
      ) {
        isMissingValues = true;
        break;
      } else {
        for (const service of session.services) {
          if (
            !service.cpt ||
            !service.diagnosisPointer ||
            !service.units ||
            !service.amount
          ) {
            isMissingValues = true;
            break;
          }
        }
      }
    }
    return isMissingValues;
  };

  const missingServiceValues = validateServiceValues();

  useMemo(() => methods.formState.errors, [methods.formState]);

  useEffect(() => {
    const validateForm = async () => {
      await methods.trigger();
      const {errors} = methods.formState;
      if (errors?.organization) {
        setMissingOrganizationValues(true);
        setCollapseBillingEntity(false);
      }
      if (errors?.patient) {
        setMissingPatientValues(true);
        setCollapsePatientEntity(false);
      }
      if (errors?.insurances) {
        setMissingInsuranceValues(true);
        setCollapseInsurance(false);
      }
      if (errors?.diagnoses) {
        setMissingDiagnosesValues(true);
        setCollapseDiagnoses(false);
      }
    };
    validateForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleSubmit = async (values: any, submit = true) => {
    if (values.patient) {
      await patient.updateEntity({
        firstName: values.patient.firstName,
        middleName: values.patient.middleName,
        lastName: values.patient.lastName,
        birthDate: values.patient.birthDate,
        gender: values.patient.gender,
        ssn: values.patient.ssn,
        billById: values.billById,
        billPhysicianId: values.billPhysicianId,
        billByPhysician:
          values.patient.billByPhysician &&
          (values.billPhysicianId === '' || !values.billPhysicianId)
            ? false
            : values.patient.billByPhysician,
        address:
          watchedPatientAddress === 'other'
            ? values.patient.address
            : caregiverAddresses.find(
                address => address.placeId === watchedPatientAddress,
              ),
      });
    }

    if (values.renderingId) {
      for (const payer of values.rendering.payers) {
        if (payer._id) {
          const payerCredential = await database
            .get(PayerCredential.table)
            .find(payer._id);
          payerCredential.updateEntity({
            legacyId: payer.legacyId,
            taxonomy: payer.taxonomy,
          });
        } else {
          await database?.write(async () => {
            return database?.get(PayerCredential.table).create(entity => {
              entity.partition = selectedGroup;
              entity.credentialId = renderingPhysician.id;
              entity.legacyId = payer.legacyId;
              entity.taxonomy = payer.taxonomy;
              entity.payerId = payer.id;
              entity.createdBy = userId;
              entity.updatedBy = userId;
            });
          });
        }
      }
      await renderingPhysician.updateEntity({
        tin: values.rendering.tin,
        npi: values.rendering.npi,
        ssn: values.rendering.ssn,
        taxonomy: values.rendering.taxonomy,
        firstName: values.rendering.firstName,
        middleName: values.rendering.middleName,
        lastName: values.rendering.lastName,
      });
      if (patient.primaryId !== values.renderingId) {
        await patient.updateEntity({
          renderingId: values.renderingId,
        });
      }
    }

    if (values.referringId) {
      for (const payer of values.referring.payers) {
        if (payer._id) {
          const payerCredential = await database
            .get(PayerCredential.table)
            .find(payer._id);
          payerCredential.updateEntity({
            legacyId: payer.legacyId,
            taxonomy: payer.taxonomy,
          });
        } else {
          await database?.write(async () => {
            return database?.get(PayerCredential.table).create(entity => {
              entity.partition = selectedGroup;
              entity.credentialId = referringPhysician.id;
              entity.legacyId = payer.legacyId;
              entity.taxonomy = payer.taxonomy;
              entity.payerId = payer.id;
              entity.createdBy = userId;
              entity.updatedBy = userId;
            });
          });
        }
      }
      // Remove Ref Physician Updates
      // if (referringPhysician.id && values.referring.firstName !== '') {
      //   await referringPhysician.updateEntity({
      //     tin: values.referring.tin,
      //     npi: values.referring.npi,
      //     ssn: values.referring.ssn,
      //     taxonomy: values.referring.taxonomy,
      //     firstName: values.referring.firstName,
      //     middleName: values.referring.middleName,
      //     lastName: values.referring.lastName,
      //   });
      // }
      if (
        patient.primaryId !== values.referringId &&
        patient?.referringId !== values.referringId
      ) {
        await patient.updateEntity({
          sameAsPrimary: false,
          referringId: values.referringId,
        });
      }
    }

    if (values.insurances.length) {
      if (Object.keys(primaryPayer).length) {
        await primaryPayer.updateEntity({
          taxonomy: values.organization.taxonomy,
        });
      }
      for (const [index, insurance] of values.insurances.entries()) {
        if (insurance._id) {
          const entity = await database
            .get(Insurance.table)
            .find(insurance._id);
          if (insurance.deletedAt) {
            await entity.delete();
          } else {
            entity.updateEntity({
              payerId: insurance.payerId,
              planId: insurance.planId,
              patientId: patient.id,
              groupNumber: insurance.groupNumber,
              memberId: insurance.memberId,
              authorizationId: index === 0 ? insurance.authorizationId : '',
              startDate: insurance.startDate,
              endDate: insurance.endDate,
              rank: index.toString(),
              subscriber: insurance.subscriber,
            });
          }
        } else {
          if (insurance.payerId) {
            await database?.write(async () => {
              return database?.get(Insurance.table).create(entity => {
                entity.partition = selectedGroup;
                entity.groupNumber = insurance.groupNumber;
                entity.memberId = insurance.memberId;
                entity.payerId = insurance.payerId;
                entity.planId = insurance.planId;
                entity.authorizationId =
                  index === 0 ? insurance.authorizationId : '';
                entity.startDate = insurance.startDate;
                entity.endDate = insurance.endDate;
                entity.rank = index.toString();
                entity.subscriber = insurance.subscriber;
                entity.patientId = patient.id;
                entity.createdBy = userId;
                entity.updatedBy = userId;
              });
            });
          }
        }
      }
    }
    if (values.diagnoses) {
      for (const [index, diagnosis] of values.diagnoses.entries()) {
        if (diagnosis.instanceDiagnosisId) {
          const diagnosisObject = await database
            .get(InstanceDiagnosis.table)
            .find(diagnosis.instanceDiagnosisId);
          if (diagnosis?._id) {
            const entity = await database
              .get(Diagnosis.table)
              .find(diagnosis._id);
            if (diagnoses?.deletedAt) {
              await entity.delete();
            } else {
              entity.updateEntity({
                diagnosis: diagnosisObject.diagnosis,
                rank: index.toString(),
                deletedAt: diagnosis?.deletedAt ?? null,
                instanceDiagnosisId: diagnosis.instanceDiagnosisId,
              });
            }
          } else {
            await database?.write(async () => {
              await database?.get(Diagnosis.table).create(entity => {
                entity.partition = selectedGroup;
                entity.diagnosis = diagnosisObject.diagnosis;
                entity.rank = index.toString();
                entity.patient.id = patient.id;
                entity.instanceDiagnosisId = diagnosis.instanceDiagnosisId;
                entity.createdBy = userId;
                entity.updatedBy = userId;
              });
            });
          }
        }
      }
    }
    for (const session of values.sessions) {
      const s = await database.get(Session.table).find(session._id);

      for (const payer of session.renderingProvider.payers) {
        if (payer._id) {
          const payerCredential = await database
            .get(PayerCredential.table)
            .find(payer._id);
          payerCredential.updateEntity({
            legacyId: payer.legacyId,
            taxonomy: payer.taxonomy,
          });
        } else {
          await database?.write(async () => {
            return database?.get(PayerCredential.table).create(entity => {
              entity.partition = selectedGroup;
              entity.credentialId = session.renderingProvider.id;
              entity.legacyId = payer.legacyId;
              entity.taxonomy = payer.taxonomy;
              entity.payerId = payer.id;
              entity.createdBy = userId;
              entity.updatedBy = userId;
            });
          });
        }
      }
      if (session.supervisingId) {
        for (const payer of session.supervisingProvider.payers) {
          if (payer._id) {
            const payerCredential = await database
              .get(PayerCredential.table)
              .find(payer._id);
            payerCredential.updateEntity({
              legacyId: payer.legacyId,
              taxonomy: payer.taxonomy,
            });
          } else {
            await database?.write(async () => {
              return database?.get(PayerCredential.table).create(entity => {
                entity.partition = selectedGroup;
                entity.credentialId = session.supervisingProvider.id;
                entity.legacyId = payer.legacyId;
                entity.taxonomy = payer.taxonomy;
                entity.payerId = payer.id;
                entity.createdBy = userId;
                entity.updatedBy = userId;
              });
            });
          }
        }
      }

      // TODO: Service Address
      await s.updateEntity({
        renderingId: session.renderingId,
        supervisingId: session.supervisingId,
        editedStartTimestamp: session.startDate,
        editedEndTimestamp: session.endDate,
        location: session.location,
        address: session.address,
        customAddress: session.customAddress,
      });

      for (const serviceLine of session.services) {
        if (serviceLine._id) {
          const sl = await database
            .get(ServiceLine.table)
            .find(serviceLine._id);

          // TODO: Deleted at updates
          await sl.updateEntity({
            userId: session.renderingId,
            startDate: moment(session.startDate).toDate(),
            endDate: moment(session.endDate).toDate(),
            cpt: serviceLine.cpt,
            modifier1: serviceLine.modifier1,
            modifier2: serviceLine.modifier2,
            modifier3: serviceLine.modifier3,
            modifier4: serviceLine.modifier4,
            location: session.location,
            units: serviceLine.units,
            amount: `${serviceLine.amount}`,
            diagnosisPointer: serviceLine.diagnosisPointer,
          });
        } else {
          await database?.write(async () => {
            await database.get(ServiceLine.table).create(entity => {
              entity.partition = selectedGroup;
              entity.claimId = claim.id;
              entity.sessionId = s.id;
              entity.userId = session.renderingId;
              entity.startDate = moment(session.startDate).toDate();
              entity.endDate = moment(session.endDate).toDate();
              entity.cpt = serviceLine.cpt;
              entity.modifier1 = serviceLine.modifier1;
              entity.modifier2 = serviceLine.modifier2;
              entity.modifier3 = serviceLine.modifier3;
              entity.modifier4 = serviceLine.modifier4;
              entity.location = session.location;
              entity.units = serviceLine.units;
              entity.amount = `${serviceLine.amount}`;
              entity.diagnosisPointer = serviceLine.diagnosisPointer;
              entity.createdBy = userId;
              entity.updatedBy = userId;
            });
          });
        }
      }
    }

    if (submit) {
      await claim.updateEntity({
        status: 'submitted',
        box8: values.box_8,
        box9b: values.box_9b,
        box9c: values.box_9c,
        box10d: values.box_10d,
        box19: values.box_19,
        box30: values.box_30,
      });
      await database?.write(async () => {
        await database?.get(ClaimEvent.table).create(entity => {
          entity.partition = selectedGroup;
          entity.claimId = claim.id;
          entity.timestamp = new Date();
          entity.type = 'submission';
          entity.description = 'User initiated submission';

          entity.createdBy = userId;
          entity.updatedBy = userId;
        });
      });
    } else {
      await claim.updateEntity({
        box8: values.box_8,
        box9b: values.box_9b,
        box9c: values.box_9c,
        box10d: values.box_10d,
        box19: values.box_19,
        box30: values.box_30,
      });
    }
    if (submit) {
      navigation.navigate('Billing', {initialTab: 'submitted'});
    } else {
      navigation.navigate('Billing', {initialTab: 'claims'});
    }
  };

  const setReferringProvider = async (id: string) => {
    if (id === 'other' || id === '') {
      methods.setValue('referring', {
        tin: '',
        npi: '',
        ssn: '',
        taxonomy: [],
        firstName: '',
        middleName: '',
        lastName: '',
        payers: watchedInsurances.map((payer: any) => {
          return {
            id: payer.payerId,
            legacyId: '',
            taxonomy: '',
          };
        }),
      });
      setReferringPhysician({
        id: null,
        tin: '',
        npi: '',
        ssn: '',
        taxonomy: [],
        firstName: '',
        middleName: '',
        lastName: '',
      });
    } else {
      const referringProvider = await database.get(Credential.table).find(id);
      const referringProviderCredentials =
        await referringProvider.activePayerCredentials.fetch();

      const mappedCredentials = _.keyBy(
        referringProviderCredentials,
        'payerId',
      );

      if (referringProvider) {
        methods.setValue('referring', {
          tin: referringProvider.tin,
          npi: referringProvider.npi,
          ssn: referringProvider.ssn,
          taxonomy: referringProvider.taxonomy,
          firstName: referringProvider.firstName,
          middleName: referringProvider.middleName,
          lastName: referringProvider.lastName,
          payers: watchedInsurances.map((insurance: any) => {
            return {
              _id: mappedCredentials[insurance.payerId]?.id,
              id: insurance.payerId,
              legacyId: mappedCredentials[insurance.payerId]?.legacyId || '',
              taxonomy: mappedCredentials[insurance.payerId]?.taxonomy || '',
            };
          }),
        });
      }
      setReferringPhysician(referringProvider);
    }
  };

  const onError = () => {
    console.log(methods.formState.errors);
  };

  useEffect(() => {
    setReferringProvider(watchedReferringId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchedReferringId]);

  const setRenderingProvider = async (id: string) => {
    if (id === 'other' || id === '') {
      methods.setValue('rendering', {
        tin: '',
        npi: '',
        ssn: '',
        taxonomy: [],
        firstName: '',
        middleName: '',
        lastName: '',
        payers: watchedInsurances.map((payer: any) => {
          return {
            id: payer.payerId,
            legacyId: '',
            taxonomy: '',
          };
        }),
      });
      setRenderingPhysician({
        id: null,
        tin: '',
        npi: '',
        ssn: '',
        taxonomy: [],
        firstName: '',
        middleName: '',
        lastName: '',
      });
    } else {
      const renderingProvider = await database.get(Credential.table).find(id);
      const user = await renderingProvider.user;
      const renderingProviderCredentials =
        await renderingProvider.activePayerCredentials.fetch();

      const mappedCredentials = _.keyBy(
        renderingProviderCredentials,
        'payerId',
      );

      if (renderingProvider) {
        methods.setValue('rendering', {
          tin: renderingProvider.tin,
          npi: renderingProvider.npi,
          ssn: renderingProvider.ssn,
          taxonomy: renderingProvider.taxonomy,
          firstName: user.firstName,
          middleName: user.middleName,
          lastName: user.lastName,
          payers: watchedInsurances.map((insurance: any) => {
            return {
              _id: mappedCredentials[insurance.payerId]?.id,
              id: insurance.payerId,
              legacyId: mappedCredentials[insurance.payerId]?.legacyId || '',
              taxonomy: mappedCredentials[insurance.payerId]?.taxonomy || '',
            };
          }),
        });
      }
      setRenderingPhysician(renderingProvider);
    }
  };
  const setBillingBy = async (id: string) => {
    //set Billing entity as entity
    const fetchedBillingEntity = watchedBillById
      ? await database.get(BillingEntity.table).find(id)
      : {
          tin: billingEntity.tin,
          npi: billingEntity.npi,
          dba: billingEntity.dba,
          address: billingEntity.address,
          taxonomy: billingEntity.taxonomy,
        };

    if (fetchedBillingEntity) {
      setBillingEntity(fetchedBillingEntity);
      methods.setValue('billById', id);

      methods.setValue('billingOrganization.address', {});
      methods.setValue('billingOrganization', {
        tin: fetchedBillingEntity.tin,
        npi: fetchedBillingEntity.npi,
        dba: fetchedBillingEntity.dba,
        address: fetchedBillingEntity.address,
        taxonomy: fetchedBillingEntity.taxonomy,
      });
    }
  };

  const setBillingPhysicianBy = async (id: string) => {
    if (watchedBillByPhysician) {
      const billByProvider = await database.get(Credential.table).find(id);
      const user = await billByProvider?.user;
      const billingProviderCredentials =
        await billByProvider.activePayerCredentials.fetch();

      const mappedCredentials = _.keyBy(billingProviderCredentials, 'payerId');

      if (billByProvider) {
        methods.setValue('billPhysicianId', id);
        methods.setValue('billingProvider', {
          tin: billByProvider.tin,
          npi: billByProvider.npi,
          ssn: billByProvider.ssn,
          taxonomy: billByProvider.taxonomy,
          firstName: user.firstName,
          middleName: user.middleName,
          lastName: user.lastName,
          payers: watchedInsurances.map((insurance: any) => {
            return {
              _id: mappedCredentials[insurance.payerId]?.id,
              id: insurance.payerId,
              legacyId: mappedCredentials[insurance.payerId]?.legacyId || '',
              taxonomy: mappedCredentials[insurance.payerId]?.taxonomy || '',
            };
          }),
        });
      }
      setBillingPhysician({
        tin: billByProvider.tin,
        npi: billByProvider.npi,
        firstName: user.firstName,
        middleName: user.middleName,
        lastName: user.lastName,
      });
    }
  };

  useEffect(() => {
    setRenderingProvider(watchedRenderingId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchedRenderingId]);

  useEffect(() => {
    setBillingBy(watchedBillById);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchedBillById]);

  useEffect(() => {
    setBillingPhysicianBy(watchedBillPhysicianId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchedBillPhysicianId]);

  return (
    <FormProvider {...methods}>
      <ScrollView
        style={[styles.flex, styles.width]}
        contentContainerStyle={[styles.affixPadding]}>
        <Text
          style={[
            Typography.H5,
            styles.textColorPrimary,
            styles.paddingTop,
            styles.paddingHorizontal,
          ]}>
          {translations('claim')}
        </Text>
        <View style={[styles.card, styles.marginVertical, styles.padding]}>
          <TouchableOpacity
            onPress={() => setCollapseBillingEntity(!collapseBillingEntity)}>
            <View style={[styles.row, styles.justifySpaceBetween]}>
              <View style={[styles.row, styles.alignCenter]}>
                <FormSectionHeader title={translations('billing_entity')} />
                {missingOrganizationValues ? (
                  <View style={[styles.row]}>
                    <Separator width={10} />
                    <Icon
                      name={'alert-circle'}
                      size={18}
                      color={Colors.TORCH_RED_DARK}
                    />
                    <Text style={[styles.textColorError, Typography.P3]}>
                      {' '}
                      {translations('missing_values')}
                    </Text>
                  </View>
                ) : (
                  <></>
                )}
              </View>
              <IconButton
                color={Colors.RAVEN_BLACK}
                icon={!collapseBillingEntity ? 'chevron-up' : 'chevron-down'}
              />
            </View>
          </TouchableOpacity>
          <Collapsible collapsed={collapseBillingEntity}>
            <>
              <HookFormSwitchInput
                name={'patient.billByPhysician'}
                label={'Bill By Physician'}
              />
              {watchedBillByPhysician ? (
                <>
                  <View style={[styles.container, styles.width]}>
                    <View style={[styles.flex]}>
                      <UserCredential
                        override={'billPhysicianId'}
                        excludePaused={true}
                      />
                    </View>
                  </View>
                  {watchedBillByPhysician && billingPhysician.firstName ? (
                    <CredentialForm
                      name={'billingProvider'}
                      box={'(Box 31)'}
                      entity={billingPhysician}
                      extraFields={false}
                      payers={watchedInsurances.map(
                        (insurance: any) => insurance.payerId,
                      )}
                      showTaxonomy={true}
                    />
                  ) : null}
                </>
              ) : (
                <>
                  <HookFormSelectInput
                    name={'billById'}
                    label={'Organization'}
                    items={billingEntities.map(
                      (billingEntityObj: BillingEntity) => ({
                        value: billingEntityObj.id,
                        label: billingEntityObj.name,
                      }),
                    )}
                  />
                  <HookFormInput
                    name={'billingOrganization.dba'}
                    label={'DBA (Doing Business As) (Box 33)'}
                  />
                  <InputGroup
                    right={
                      <TIN.Input
                        name={'billingOrganization'}
                        label={'Federal Tax ID Number (Box 25)'}
                      />
                    }
                    left={
                      <NPI.Input
                        name={'billingOrganization'}
                        label={'NPI (Box 33 a.)'}
                      />
                    }
                  />
                  <TaxonomySelector
                    entity={billingEntity}
                    name={'billingOrganization'}
                    label={'Taxonomy (Box 33 b.)'}
                  />
                  <AddressAutocompleteInput
                    name={'billingOrganization.address'}
                    label={'Billing Address (Box 33 Cont.)'}
                  />
                </>
              )}
            </>
          </Collapsible>
        </View>
        <View style={[styles.card, styles.marginVertical, styles.padding]}>
          <TouchableOpacity
            onPress={() => setCollapsePatientEntity(!collapsePatientEntity)}>
            <View style={[styles.row, styles.justifySpaceBetween]}>
              <View style={[styles.row, styles.alignCenter]}>
                <FormSectionHeader title={translations('patient_entity')} />
                {missingPatientValues ? (
                  <View style={[styles.row]}>
                    <Separator width={10} />
                    <Icon
                      name={'alert-circle'}
                      size={18}
                      color={Colors.TORCH_RED_DARK}
                    />
                    <Text style={[styles.textColorError, Typography.P3]}>
                      {' '}
                      {translations('missing_values')}
                    </Text>
                  </View>
                ) : (
                  <></>
                )}
              </View>
              <IconButton
                color={Colors.RAVEN_BLACK}
                icon={!collapsePatientEntity ? 'chevron-up' : 'chevron-down'}
              />
            </View>
          </TouchableOpacity>
          <Collapsible collapsed={collapsePatientEntity}>
            <>
              <View style={[styles.container, styles.width]} />
              <View style={[styles.container, styles.width]}>
                <View style={[styles.flex]}>
                  <FirstName.Input
                    name={'patient'}
                    required={true}
                    label={'First Name (Box 2)'}
                  />
                </View>
                <Separator width={20} height={20} />
                <View style={[styles.flex]}>
                  <MiddleName.Input
                    name={'patient'}
                    label={'Middle Name (Box 2)'}
                  />
                </View>
                <Separator width={20} height={20} />
                <View style={[styles.flex]}>
                  <LastName.Input
                    name={'patient'}
                    required={true}
                    label={'Last Name (Box 2)'}
                  />
                </View>
              </View>
              <View style={[styles.container, styles.width]}>
                <View style={[styles.flex]}>
                  <Gender.Input name={'patient'} label={'Gender (Box 3)'} />
                </View>
                <Separator width={20} height={20} />
                <View style={[styles.flex]}>
                  <BirthDate.Input
                    name={'patient'}
                    label={'Birth Date (Box 3)'}
                  />
                </View>
                <Separator width={20} height={20} />
                <View style={[styles.flex]}>
                  <SSN.Input name={'patient'} />
                </View>
              </View>
              <View style={[styles.container, styles.width]}>
                <View style={[styles.flex]}>
                  <HookFormSelectInput
                    label={'Patient Addresses (Box 5)'}
                    name={'patient.patientSavedAddress'}
                    items={[
                      ...patientAndCaregiverAddresses.map((address: any) => ({
                        value: address.placeId,
                        label: addressFormat(address),
                      })),
                      {
                        value: 'other',
                        label: 'Other',
                      },
                    ]}
                  />
                </View>
              </View>
              {watchedPatientAddress === 'other' ? (
                <View style={[styles.container, styles.width]}>
                  <View style={[styles.flex]}>
                    <AddressAutocompleteInput
                      name={'patient.address'}
                      label={'Address (Box 5)'}
                    />
                  </View>
                </View>
              ) : (
                <></>
              )}
            </>
          </Collapsible>
        </View>
        <View style={[styles.card, styles.marginVertical, styles.padding]}>
          <TouchableOpacity
            onPress={() => setCollapseInsurance(!collapseInsurance)}>
            <View style={[styles.row, styles.justifySpaceBetween]}>
              <View style={[styles.row, styles.alignCenter]}>
                <FormSectionHeader title={translations('insurance')} />
                {missingInsuranceValues ? (
                  <View style={[styles.row]}>
                    <Separator width={10} />
                    <Icon
                      name={'alert-circle'}
                      size={18}
                      color={Colors.TORCH_RED_DARK}
                    />
                    <Text style={[styles.textColorError, Typography.P3]}>
                      {' '}
                      {!watchedInsurances.length
                        ? methods?.formState?.errors?.insurances?.message || ''
                        : translations('missing_values')}
                    </Text>
                  </View>
                ) : (
                  <></>
                )}
              </View>
              <IconButton
                color={Colors.RAVEN_BLACK}
                icon={!collapseInsurance ? 'chevron-up' : 'chevron-down'}
              />
            </View>
          </TouchableOpacity>
          <Collapsible collapsed={collapseInsurance}>
            <InsuranceForm
              showTitle={false}
              showAuthorizations={true}
              showBoxNumbers={true}
              showToggle={false}
            />
          </Collapsible>
        </View>
        <View style={[styles.card, styles.marginVertical, styles.padding]}>
          <TouchableOpacity
            onPress={() => setCollapseDiagnoses(!collapseDiagnoses)}>
            <View style={[styles.row, styles.justifySpaceBetween]}>
              <View style={[styles.row, styles.alignCenter]}>
                <FormSectionHeader title={translations('diagnoses')} />
                {missingDiagnosesValues ? (
                  <View style={[styles.row]}>
                    <Separator width={10} />
                    <Icon
                      name={'alert-circle'}
                      size={18}
                      color={Colors.TORCH_RED_DARK}
                    />
                    <Text style={[styles.textColorError, Typography.P3]}>
                      {' '}
                      {translations('missing_values')}
                    </Text>
                  </View>
                ) : (
                  <></>
                )}
              </View>
              <IconButton
                color={Colors.RAVEN_BLACK}
                icon={!collapseDiagnoses ? 'chevron-up' : 'chevron-down'}
              />
            </View>
          </TouchableOpacity>
          <Collapsible collapsed={collapseDiagnoses}>
            <DiagnosesForm
              hideExtraFields={true}
              showTitle={false}
              showBoxNumbers={true}
            />
          </Collapsible>
        </View>
        <View style={[styles.card, styles.marginVertical, styles.padding]}>
          <FormSectionHeader title={translations('payer_information')} />
          {watchedInsurances.map((payer: any, index: number) => {
            return payer.payerId ? (
              <View key={`payer-item-${payer}-${index}`}>
                <PayerItemDisplay payer={payer.payerId} />
              </View>
            ) : (
              <></>
            );
          })}
        </View>
        <View style={[styles.card, styles.marginVertical, styles.padding]}>
          <View style={[styles.row, styles.alignCenter]}>
            <FormSectionHeader title={translations('services')} />
            {missingServiceValues ? (
              <View style={[styles.row]}>
                <Separator width={10} />
                <Icon
                  name={'alert-circle'}
                  size={18}
                  color={Colors.TORCH_RED_DARK}
                />
                <Text style={[styles.textColorError, Typography.P3]}>
                  {' '}
                  {translations('missing_values')}
                </Text>
              </View>
            ) : (
              <></>
            )}
          </View>
          <SessionLineForm claim={claim} />
        </View>

        <View style={[styles.card, styles.marginVertical, styles.padding]}>
          <FormSectionHeader
            title={translations('additional_claim_information')}
          />
          <View style={[styles.paddingLVertical]}>
            <Text style={[Typography.P3_BOLD]}>
              Claim Rendering Provider (Box 31)
            </Text>
          </View>
          <View style={[styles.container, styles.width]}>
            <View style={[styles.flex]}>
              <UserCredential override={'renderingId'} excludePaused={true} />
            </View>
          </View>
          {watchedRenderingId !== '' ? (
            <CredentialForm
              name={'rendering'}
              box={'(Box 31)'}
              entity={renderingPhysician}
              extraFields={false}
              payers={watchedInsurances.map(
                (insurance: any) => insurance.payerId,
              )}
              showTaxonomy={true}
            />
          ) : null}
          <View style={[styles.paddingLVertical]}>
            <Text style={[Typography.P3_BOLD]}>
              Referring Physician (Box 17)
            </Text>
          </View>
          <View style={[styles.container, styles.width]}>
            <View style={[styles.flex]}>
              <CredentialSelector allowOther={false} override={'referringId'} />
            </View>
          </View>
          {watchedReferringId !== '' ? (
            <CredentialForm
              name={'referring'}
              box={'(Box 17)'}
              entity={referringPhysician}
              extraFields={false}
              payers={watchedInsurances.map(
                (insurance: any) => insurance.payerId,
              )}
              showTaxonomy={true}
            />
          ) : null}
          <View style={[styles.paddingLVertical]}>
            <Text style={[Typography.P3_BOLD]}>
              {translations('additional_claim_information')}
            </Text>
          </View>
          <View style={[styles.container, styles.width]}>
            <View style={[styles.flex]}>
              <HookFormInput
                name={'box_8'}
                label={'Reserved for NUCC Use (Box 8)'}
              />
            </View>
            <Separator width={20} height={20} />
            <View style={[styles.flex]}>
              <HookFormInput
                name={'box_9b'}
                label={'Reserved for NUCC Use (Box 9b)'}
              />
            </View>
            <Separator width={20} height={20} />
            <View style={[styles.flex]}>
              <HookFormInput
                name={'box_9c'}
                label={'Reserved for NUCC Use (Box 9c)'}
              />
            </View>
          </View>
          <View style={[styles.container, styles.width]}>
            <View style={[styles.flex]}>
              <HookFormInput name={'box_10d'} label={'Claim Codes (Box 10d)'} />
            </View>
          </View>
          <View style={[styles.container, styles.width]}>
            <View style={[styles.flex]}>
              <HookFormInput
                name={'box_19'}
                label={'Additional Claim Information (Box 19)'}
              />
            </View>
          </View>
          <View style={[styles.container, styles.width]}>
            <View style={[styles.flex]}>
              <HookFormInput
                name={'box_30'}
                label={'Reserved for NUCC Use (Box 30)'}
              />
            </View>
          </View>
        </View>
      </ScrollView>
      <View
        style={[
          styles.affix,
          styles.paddingHorizontal,
          styles.row,
          styles.justifySpaceBetween,
          styles.elevation,
        ]}>
        <View style={[styles.column, styles.justifyCenter]} />

        <View style={[styles.row, styles.alignCenter]}>
          <RHButton
            style={styles.marginLRight}
            textColor={Colors.RAVEN_BLACK}
            color="black"
            mode="outlined"
            onPress={async () => {
              const values = methods.getValues();
              await handleSubmit(values, false);
            }}>
            {translations('save_as_draft')}
          </RHButton>
          <View style={[styles.column, styles.justifyCenter]}>
            <RHButton
              mode="contained"
              onPress={() => {
                methods.handleSubmit(async values => {
                  await handleSubmit(values, true);
                }, onError)();
              }}>
              {translations('submit_claim')}
            </RHButton>
          </View>
        </View>
      </View>
    </FormProvider>
  );
};

export default compose(
  withDatabase,
  withState,
  withObservables([], ({authentication, database, claim}: any) => ({
    organization: database
      ?.get(Instance.table)
      .query(Q.where('_partition', authentication.selectedGroup))
      .observe()
      .pipe(mergeMap((x: any) => x)),
    profile: authentication?.userId
      ? database.get(User.table).findAndObserve(authentication.userId)
      : of(),
    patient: claim.patient,
    sessions: claim.sessions,
    billingEntities: database
      .get(BillingEntity.table)
      .query(
        Q.where('deleted_at', null),
        Q.where('_partition', authentication.selectedGroup),
      ),
  })),
  withObservables([], ({database, patient, sessions, claim}: any) => {
    const sessionIds = sessions.map((session: any) => session.id);
    return {
      services: database
        .get(ServiceLine.table)
        .query(
          Q.where('session_id', Q.oneOf(sessionIds)),
          Q.where('claim_id', claim.id),
          Q.where('deleted_at', null),
        ),
      insurances: patient.activeInsurances,
      diagnoses: patient.activeDiagnoses,
      caregivers: patient.activeCaregivers,
      rendering: patient.renderingId
        ? patient.rendering
        : of({
            id: '',
            tin: '',
            npi: '',
            ssn: '',
            taxonomy: [],
            firstName: '',
            middleName: '',
            lastName: '',
          }),
      billingProvider:
        patient.billByPhysician && patient.billPhysicianId
          ? patient.billingPhysician
          : of({
              id: '',
              tin: '',
              npi: '',
              ssn: '',
              taxonomy: [],
              firstName: '',
              middleName: '',
              lastName: '',
            }),
      billingOrganization:
        patient.billById && !patient.billByPhysician
          ? patient.billingEntity
          : of({
              id: '',
              name: '',
              dba: '',
              tin: '',
              npi: '',
              taxonomy: [],
            }),
      referring: patient.sameAsPrimary
        ? patient.primaryId
          ? patient.primary
          : of({
              id: '',
              tin: '',
              npi: '',
              ssn: '',
              taxonomy: [],
              firstName: '',
              middleName: '',
              lastName: '',
            })
        : patient.referringId
        ? patient.referring
        : of({
            id: '',
            tin: '',
            npi: '',
            ssn: '',
            taxonomy: [],
            firstName: '',
            middleName: '',
            lastName: '',
          }),
    };
  }),
  withObservables([], ({profile, database, insurances}: any) => {
    return {
      role: profile.role,
      initialPrimaryPayer: insurances.length
        ? database.get(Payer.table).findAndObserve(insurances[0].payerId)
        : of({}),
    };
  }),
)(ClaimForm);
